var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "c-button c-button--primary",
      class: { "c-button--disabled": _vm.isButtonDisabled },
      attrs: {
        "data-controller": _vm.dataControllerBinding,
        "data-turbolinks": "false",
        "data-flitem": "shy-left--",
        title: "There is already a new task in progress",
        href: "#",
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.showSimpleTaskForm.apply(null, arguments)
        },
      },
    },
    [_vm._v("\n  + Add task\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }