// <div data-controller="upload">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'preview' ]

  setFile(event) {
    const fakeEvent = document.createEvent('Event');
    fakeEvent.initEvent('change', true, true);

    const reader = new FileReader();
    reader.onload = function() {
      this.previewTarget.src = reader.result;
      this.previewTarget.dispatchEvent(fakeEvent);
    }.bind(this, fakeEvent);
    reader.readAsDataURL(event.target.files[0]);

    this.element.classList.add('is-active');
  }
}
