import { Controller } from 'stimulus';
import { infoNotification, errorNotification } from '../components/notifications';
const _ = require('lodash/core');

export default class extends Controller {
  static targets = ['wrapper', 'jobName', 'editForm', 'textField'];

  editting = false;
  id = undefined;

  connect() {
    this.id = this.element.dataset.id;

    document.addEventListener('mousedown', event => {
      const contained = this.editFormTarget.contains(event.target);
      const inWrapper = this.wrapperTarget.contains(event.target);

      if (!contained && this.editting) {
        this.nameUpdate();
      } else if (!this.editting && inWrapper) {
        this.toggleEdit();
      } else if (!inWrapper) {
        this.stopEdit();
      }
    });
  }

  toggleEdit() {
    this.editting = !this.editting;
    this.editting ? this.edit() : this.stopEdit();
  }

  edit(event) {
    if (event) {
      event.preventDefault();
    }

    this.editting = true;
    this.wrapperTarget.classList.add('is-hide');
    this.jobNameTarget.classList.add('u-hidden');
    this.editFormTarget.classList.remove('u-hidden');
    this.textFieldTarget.value = this.currentFullName();
    this.textFieldTarget.select();

    // We need to wait a little bit for this to work
    setTimeout(() => this.textFieldTarget.focus(), 200);
  }

  stopEdit(event) {
    if (event) {
      event.preventDefault();
    }

    this.editting = false;
    this.jobNameTarget.classList.remove('u-hidden');
    this.editFormTarget.classList.add('u-hidden');
    this.wrapperTarget.classList.remove('is-hide');
  }

  nameUpdate(event) {
    if (event) {
      event.preventDefault();
    }

    const oldName = this.currentFullName();
    const newName = this.textFieldTarget.value;

    // Just stop edit and return if the name didn't change or if the user submitted an empty field.
    if (oldName === newName || newName.length == 0) {
      this.stopEdit();
      return;
    }

    this.jobNameTarget.innerHTML = '<span class="u-color-blue">Renaming...</span>';
    this.stopEdit();

    // We always wait at least 500ms to avoid flickering when the request is too fast.
    setTimeout(() => this.sendUpdate({ newName, oldName }), 500);
  }

  sendUpdate({ newName, oldName }) {
    const purifiedName = encodeURIComponent(newName);

    window.Rails.ajax({
      url: `/my_day/jobs/${this.id}.json`,
      type: 'PUT',
      dataType: 'json',
      data: `job[name]=${purifiedName}`,
      success: () => {
        infoNotification('Rename complete.');
        this.jobNameTarget.innerHTML = _.escape(newName);
        this.wrapperTarget.title = _.escape(newName);
      },
      error: () => {
        errorNotification('Rename failed.');
        this.jobNameTarget.innerHTML = _.escape(oldName);
      }
    });
  }

  keyStroke(event) {
    // ENTER - submit the name change
    if (event.keyCode === 13) {
      this.nameUpdate();
    }
    // ESC - cancel the name change
    if (event.keyCode === 27) {
      this.stopEdit();
    }
  }

  currentFullName() {
    return this.jobNameTarget.textContent.trim();
  }
}
