var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("pixie-select", {
    attrs: {
      "allow-empty": "true",
      placeholder: "No client selected",
      search: true,
      options: _vm.options,
      value: _vm.selectedValue,
    },
    model: {
      value: _vm.selectedValue,
      callback: function ($$v) {
        _vm.selectedValue = $$v
      },
      expression: "selectedValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }