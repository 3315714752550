import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['file', 'button', 'isupload'];

  connect() {

    const self = this;

    // Reset Drop Area -> is-error, is-upload
    document.querySelectorAll('[data-js=\'close-modal\']').forEach(function(e) {
      e.onclick = function() {
        self.buttonTarget.classList.remove('is-error');
      };
    });

    // File type verification
    this.fileTarget.onchange = function() {
      const extension = self.fileTarget.files[0].name.split('.').pop().toLowerCase();
      if(extension == 'csv') {
        self.buttonTarget.style.display = 'none';
        self.isuploadTarget.classList.remove('u-hidden');
        //Auto-Submit
        self.fileTarget.closest('form').submit();
      }
      else {
        self.buttonTarget.classList.add('is-error');
      }
    };

  }

  startHover(event) {
    event.preventDefault();
    this.element.classList.add('is-hover');
  }
  endHover(event) {
    event.preventDefault();
    this.element.classList.remove('is-hover');
  }

}
