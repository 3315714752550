import axios from 'axios';
import Noty from 'noty';
import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', this.click.bind(this));
  }

  click(e) {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();

    // PUT /jobs/1/complete.json
    axios.put(e.currentTarget.getAttribute('href')).then(this.responseProcess);
  }

  responseProcess(response) {
    // Remove job row from table
    const el = document.getElementById(`job-row-${response.data.id}`);
    el.parentNode.removeChild(el);

    // Render user notification that task was completed
    new Noty({
      type: 'info noty_content',
      text: `<p>${response.data.notice}</p>`,
      timeout: 5000,
      progressBar: false,
      buttons: [
        Noty.button('', 'noty_btn noty_btn--close')
      ]
    }).show();
  }
}
