// <div data-controller="header">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'input', 'toggle', 'toggler' ]

  connect() {
    //this.toggle()

    if(document.body.classList.contains('is-collapsed') && this.toggleTargets.length > 0) {
      this.toggleTargets[0].classList.add('u-hidden');
      this.toggleTargets[1].classList.remove('u-hidden');
      this.togglerTarget.classList.remove('u-hidden');
    }
  }
  toggle(e) {
    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
    document.body.classList.toggle('is-collapsed');
    this.toggleElements();
  }
  toggleElements() {
    this.toggleTargets.forEach((e) => {
      e.classList.toggle('u-hidden');
    });
  }

  clear() {
    this.inputTarget.value = '';
  }
}
