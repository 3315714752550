export default class RegionalUtils {

  static get currency() {
    return window.PixieUser.regional.currency;
  }

  static get us_date_format() {
    return window.PixieUser.regional.date_format === 'us';
  }

  static get global_input() {
    return 'YYYY-MM-DD';
  }

  static get format_date_input() {
    if (this.us_date_format) {
      return 'MM-DD-YYYY';
    } else {
      return 'DD-MM-YYYY';
    }
  }

  static get format_day_month_year() {
    if (this.us_date_format) {
      return 'MMM D, YYYY';
    } else {
      return 'D MMM YYYY';
    }
  }

  static get format_time_only() {
    return 'h:mma';
  }

  static get format_day_month_only() {
    if (this.us_date_format) {
      return 'MMM D';
    } else {
      return 'D MMM';
    }
  }

  static get format_date_time() {
    if (this.us_date_format) {
      return 'MMM D, YYYY h:mma';
    } else {
      return 'D MMM YYYY h:mma';
    }
  }

  static get format_day_month_time() {
    if (this.us_date_format) {
      return 'MMM D h:mma';
    } else {
      return 'D MMM h:mma';
    }
  }
}
