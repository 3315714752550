// <div data-controller="workspace">
// </div>

import { Controller } from 'stimulus';
import parameterize from 'parameterize';

export default class extends Controller {
  static targets = [ 'firmname', 'subdomain' ];

  connect() {
    this.allowAutoChange = true;
  }

  nameChanged() {
    if (!this.allowAutoChange) return;

    const firmName = this.firmnameTarget.value.trim();
    if (firmName.length == 0) return;

    this.subdomainTarget.value = parameterize(this.firmnameTarget.value);
    const input = this.subdomainTarget;
    setTimeout(() => {
      input.selectionStart = input.selectionEnd;
    }, 1);
  }

  subdomainChanged() {
    const subdomain = this.subdomainTarget.value.trim();
    this.allowAutoChange = (subdomain.length == 0);
  }
}
