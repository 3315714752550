// <div data-controller="modal">
// </div>

import { Controller } from 'stimulus';
import MicroModal from 'micromodal';

import { requestUserConfirmation } from '../components/notifications';

export default class extends Controller {
  connect() {
    MicroModal.init({
      // Focus on inputs that have the autofocus true when opening the modal
      onShow: modal => {
        const input = modal.querySelector('input[autofocus]');

        if (input) {
          input.focus();
        }
      }
    });
    this.element.dataset.modalInitialised = true;

    const returnTo = this.element.dataset.returnTo;

    //.c-dialog
    const modalConfig = {
      onClose: modal => {
        if (returnTo) {
          window.location = returnTo;
        } else {
          const closebtn = modal.querySelector('[data-micromodal-close]');
          if(closebtn != null && typeof closebtn.dataset.onclose != 'undefined') {
            window.location = closebtn.dataset.onclose;
          }
        }
      },
      awaitCloseAnimation: true
    };

    document.body.querySelectorAll('[data-js~=\'dialog\']').forEach(element => {
      if (typeof element.dataset.modalOpen != 'undefined') {
        MicroModal.show(element.id, modalConfig);
      }
    });
  }
  close(e) {
    if(e.keyCode == 27) {
      e.preventDefault();
      this.closeHandler();
    }
  }
  closeConfirmation(e) {
    if(e.keyCode == 27) {
      const confirmation = this.element.dataset.confirmationMsg;
      requestUserConfirmation(confirmation, async () => {
        e.preventDefault();
        this.closeHandler();
      }, 'c-icon--warning--danger');
    }
  }
  disconnect() {
    const dialogId = this.element.dataset.MicromodalTrigger;
    if (dialogId == null) return;
    MicroModal.close(dialogId);
  }

  closeHandler() {
    const returnTo = this.element.dataset.returnTo;
    if (returnTo) {
      window.location = returnTo;
      return;
    } else {
      const closebtn = this.element.querySelector('[data-micromodal-close]');
      if (closebtn != null && typeof closebtn.dataset.onclose != 'undefined') {
        window.location = closebtn.dataset.onclose;
        return;
      }
    }
  }
}
