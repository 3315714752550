/* global Calendly */
import { Controller } from 'stimulus';

function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
}

export default class extends Controller {

  initialize() {
    this.calendlyLink = this.element.getAttribute('data-calendly-link');
    this.academySession = this.element.getAttribute('data-calendly-session');
    this.setupPrefillOptions();
    this.setupCalendlyEventListener();
  }

  setupPrefillOptions() {
    this.prefill = null;
    if (window.PixieUser) {
      this.prefill = {
        name: window.PixieUser.name,
        email: window.PixieUser.email
      };
    }
  }

  setupCalendlyEventListener() {
    if (window.PixieCalendlyListenerSetup) return;

    window.addEventListener('message',  (e) => {
      if (!isCalendlyEvent(e)) { return; }

      switch(e.data.event) {
        case 'calendly.event_type_viewed':
          this.trackAnalytics('Viewed academy availability');
          break;
        case 'calendly.date_and_time_selected':
          this.trackAnalytics('Selected academy session');
          break;
        case 'calendly.event_scheduled':
          this.trackAnalytics('Registered academy session');
          break;
      }
    });

    window.PixieCalendlyListenerSetup = true;
  }

  connect() {
  }

  disconnect() {
  }

  showCalendly() {
    Calendly.initPopupWidget({
      url: `${this.calendlyLink}?hide_gdpr_banner=1&text_color=252525&primary_color=fb2152`,
      prefill: this.prefill
    });
  }

  trackAnalytics(eventName) {
    if (!window.analytics) return;

    window.analytics.ready(() => {
      window.analytics.track(eventName, {
        session: this.academySession
      });
    });
  }

}
