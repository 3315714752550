/* eslint-disable */
// <div data-controller="styleguide">
// </div>

import { Controller } from 'stimulus';
import tippy from 'tippy.js';
import MicroModal from 'micromodal';

export default class extends Controller {
  connect() {
    //.c-button--slider
    this.element.querySelectorAll('.c-button--slider').forEach(function(e) {
      e.addEventListener('click', function() {
        this.parentElement.querySelector('.c-button--slider.is-active').classList.remove('is-active');
        setTimeout(function() {
          this.classList.add('is-active');
        }.bind(this), 5);

      });
    });

    //.c-dialog
    MicroModal.init();

    //.Notifications -> Noty Actions
    //.noty__type_alert
    document.querySelector('[data-js="f1"]').addEventListener('click', function() {NotyPop('alert', 'E-mail deleted.', 'noty--primary');});
    document.querySelector('[data-js="f2"]').addEventListener('click', function() {NotyPop('feedback', 'E-mail Sent.', 'noty--secondary');});
    document.querySelector('[data-js="f3"]').addEventListener('click', function() {NotyPop('notification', 'Vat Returns', 'noty--primary');});

    function NotyPop(_type, _text, _h) {
      var n = new Noty({
        type: _type + ' noty_content ' +_h,
        text: '<div class="noty_icon u-mr-medium"></div><p>'+_text+'<span class="noty_body--type">New Urgent Tasks</span></p>',
        buttons: [
          Noty.button('Whitesmith', 'noty_btn noty_btn--client', function () {
            console.log('button 1 clicked');
          }, { id: 'button1', 'data-status': 'ok' }),

          Noty.button('View Task', 'noty_btn noty_btn--action', function () {
            console.log('button 2 clicked');
          }, { id: 'button2', 'data-status': 'ok' }),

          Noty.button('Undo', 'noty_btn noty_btn--undo', function () {
            console.log('button 3 clicked');
          }, { id: 'button3', 'data-status': 'ok' }),

          Noty.button('', 'noty_btn noty_btn--close', function () {
            n.close();
          })
        ]
      }).show();
    }
  }
}
