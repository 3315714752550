// <div data-controller="anchor">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'a' ]

  to(e) {
    const container = document.getElementById(e.target.dataset.container);
    const block = document.getElementById(e.target.dataset.blockId);
    this.toggleState();

    container.scrollTo({ top: block.offsetTop, left: 0, behavior: 'smooth' });

    setTimeout(this.toggleState.bind(this), 5000);
  }

  toggleState() {
    const block = document.getElementById(this.context.element.dataset.blockId);

    block.classList.toggle('c-card--secondary');
    block.classList.toggle('c-card--primary');
    block.classList.toggle('is-active');
  }
}
