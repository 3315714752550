import { Controller } from 'stimulus';

import Pickr from '@simonwep/pickr';

export default class extends Controller {
  static targets = ['picker', 'input']

  initialize() {
    this.initPicker();
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,
      swatches: [
        '#FB2152',
        '#780A23',
        '#FB7E9A',
        '#FBF6F7',
        '#0E1F77',
        '#335CFF',
        '#8099FF',
        '#249E82',
        '#8AF6DE',
        '#3A1A5B',
        '#9213E4',
        '#FAF3FC',
        '#AB8A00',
        '#FAE694',
        '#FBF5DD',
        '#FFD633'
      ],
      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
      i18n: {
        'btn:save': 'Apply'
      }
    });

    this.picker.on('save', (color/*, _instance*/) => {
      this.inputTarget.value = color.toHEXA().toString();

      this.picker.hide();
    }).on('swatchselect', (color, instance) => {
      instance.applyColor();
    });
  }
}
