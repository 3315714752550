import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'collapsed_element' ]

  connect() {
    this.collapsed = true;
  }

  toggle() {
    if (this.collapsed) {
      this.collapsed_elementTarget.style.display = 'block';
      this.collapsed = false;
    } else {
      this.collapsed_elementTarget.style.display = 'none';
      this.collapsed = true;
    }
  }
}
