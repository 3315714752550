// app/javascript/controllers/signature_request_controller.js

import { Controller } from 'stimulus';
import { infoNotification, errorNotification } from '../components/notifications';
import axios from 'axios';

axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['Accept'] = 'application/json';

export default class extends Controller {
  handleSingleFile(event) {
    const supportedFormats = ['pdf', 'docx', 'txt', 'png', 'jpg', 'ppt'];
    const blob = JSON.parse(event.currentTarget.dataset.blobJson);
    const fileExtension = blob.filename.split('.').pop().toLowerCase();

    if (!supportedFormats.includes(fileExtension)) {
      errorNotification(`Unsupported file format: ${fileExtension}`);
      return false;
    }

    axios.post('tasks', { task: { type: 'Job::TaskRequestSignature' }, blob_ids: [blob.id] })
      .then(response => {
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else if (response.data.notice) {
          infoNotification(response.data.notice);
        } else if (response.data.error) {
          errorNotification(response.data.error);
        }
      })
      .catch(error => {
        const errorMessage = error.response?.data?.error || 'ERROR: Could not create signature request';
        errorNotification(errorMessage);
      });
    return true;
  }
}
