// <div data-controller="timestamp"></div>

import { Controller } from 'stimulus';
import { formatDateWithTimezone } from '../components/utils/date_utils';
import RegionalUtils from '../components/utils/regional_utils';

export default class extends Controller {
  connect() {
    const format = this.element.dataset.format || RegionalUtils.format_date_time;
    const timestamp = this.element.dataset.timestamp;

    this.element.innerText = formatDateWithTimezone(timestamp, format);
  }
}
