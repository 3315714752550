// <div data-controller="scroll">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'a' ]

  bottom() {
    setTimeout(() => {
      this.element.scrollTop = this.element.scrollHeight;
    }, 0);
  }
}
