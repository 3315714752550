<template>
  <form
    ref="form"
    :class="{ 'is-active': formVisible }"
    class="c-table__row--inline u-col-12"
    :action="formAction"
    accept-charset="UTF-8"
    method="post"
    enctype="multipart/form-data">
    <input name="utf8" type="hidden" value="✓">
    <div class="c-table__cell c-table__cell--flex-35 u-pl-large u-pr c-table__row--job">
      <input
        ref="taskNameInput"
        v-model="taskName"
        required="required"
        placeholder="Task name"
        class="c-input c-input--primary"
        type="text"
        name="task[name]"
        autocomplete="off"
        @keyup.enter="createSimpleTask"
        @keyup.esc="cancelSimpleTaskCreation">
    </div>
    <div class="c-table__cell c-table__cell--flex-25">
      <client-list-select v-model="taskClientId">
      </client-list-select>
    </div>
    <div class="c-table__cell c-table__cell--flex-25">
      ---
    </div>
    <div class="c-table__cell c-table__cell--flex-25">
      <vue-pikaday
        v-model="taskDeadline"
        :options="pikadayOptions"
        class="c-input c-input--primary"
        placeholder="Pick a date"
        name="task[deadline]" />
    </div>
    <div class="c-table__cell c-table__cell--flex-20">
      ---
    </div>
    <div class="c-table__cell c-table__cell--flex-30 c-table__cell--actions u-pl u-pv-none" data-flout="end--">
      <button type="button" class="c-button c-button--neutral u-mr" :disabled="submitting" @click.prevent="cancelSimpleTaskCreation">
        {{ $t('labels.actions.cancel') }}
      </button>
      <loading-button
        :classes="['c-button', 'c-button--blue']"
        :loading="submitting"
        :text="$t('labels.actions.save_task')"
        :text-loading="$t('labels.actions.saving_task')"
        @click.prevent="createSimpleTask" />
    </div>
  </form>
</template>

<script>
  import axios from 'axios';
  import Pixie from '../../shared/globals';
  import FormValidator from '../../mixins/vue_hyperform';
  import Notifications from '../../mixins/noty';
  import Logger from '../../mixins/logger';
  import ClientListSelect from '../../components/jobs/ClientListSelect';
  import LoadingButton from '../../components/LoadingButton';
  import moment from 'moment-timezone';
  import RegionalUtils from '../utils/regional_utils';

  axios.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].getAttribute('content');
  axios.defaults.headers.common['Accept'] = 'application/json';

  export default {
    components: {
      ClientListSelect,
      LoadingButton
    },
    mixins: [FormValidator, Notifications, Logger],
    props: {
      initialFormVisible: Boolean,
      initialFormAction: String
    },
    data() {
      return {
        formAction: this.initialFormAction,
        formVisible: this.initialFormVisible,
        submitting: false,
        taskName: '',
        taskNameInput: '',
        taskDeadline: new Date(), /* default deadline is today */
        taskClientId: null,
        pikadayOptions: {
          format: RegionalUtils.global_input,
          position: 'bottom left',
          reposition: false
        },
      };
    },
    computed: {
      saveButtonLabel() {
        return this.submitting ? 'labels.actions.saving_task' : 'labels.actions.save_task';
      }
    },
    created() {
      Pixie.EventBus.$on('pixie:tasks:add_new:click', value => {
        value === 'show' ? this.showForm() : this.hideForm();
      });
    },
    mounted() {
      const timezone = document.head.querySelector('meta[name="timezone"]').content;
      moment.tz.setDefault(timezone);
    },
    methods: {
      createSimpleTask() {
        const data = {
          task: {
            name: this.taskName,
            client_id: this.taskClientId,
            deadline: this.taskDeadline,
          }
        };

        if (this.validForm()) {
          this.submitting = true;

          axios.post(this.$refs.form.action, data)
            .then(response => {
              if (!response.data.task.id) {
                return;
              }
              this.showInformation('Task created.');
              window.location.reload();
            })
            .catch(error => {
              this.logError(error);
              this.submitting = false;
              this.cancelSimpleTaskCreation();
            });
        }
      },
      cleanForm() {
        this.taskName = '';
        this.taskClientId = null;
        this.taskDeadline = new Date(); /* default deadline is today */
      },
      hideForm() {
        this.formVisible = false;
        this.cleanForm();
        this.destroyFormValidator();
      },
      showForm() {
        this.formVisible = true;
        this.bindFormValidator();
        this.$nextTick(() => this.$refs.taskNameInput.focus());
      },
      cancelSimpleTaskCreation() {
        Pixie.EventBus.$emit('pixie:tasks:add_new:cancel');
        this.hideForm();
      },
      getValidationForm() {
        return this.$refs.form;
      }
    }
  };
</script>
