<template>
  <button :class="classes" :disabled="loading" @click="onClick">
    {{ buttonLabel }}
    <span v-if="loading" class="c-loader c-loader--xs c-loader--gris u-ml"></span>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    textLoading: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    classes: {
      type: Array,
      default: () => ['c-button', 'c-button--primary']
    }
  },
  computed: {
    buttonLabel() {
      return this.loading ? this.textLoading : this.text;
    }
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    }
  }
};
</script>
