<template>
  <div>
    <input :id="`datepicker${jobId}`" v-model="selectedDate" type="hidden">
    <a :id="`datepicker-button${jobId}`" class="u-ts-4 u-color-gris-dark c-button--editable-date">
      <span class="date u-va-middle">{{ humanFriendlyDate }}</span>
    </a>
  </div>
</template>

<script>
  import Pikaday from 'pikaday';
  import moment from 'moment';
  import { infoNotification, errorNotification } from '../../components/notifications';
  import RegionalUtils from '../utils/regional_utils';

  export default {
    props: {
      date: {
        type: String,
        default: ''
      },
      jobId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        selectedDate: this.date
      };
    },
    computed: {
      humanFriendlyDate: function() {
        return moment(this.selectedDate).format(RegionalUtils.format_day_month_year);
      }
    },
    destroyed() {
      if (this.picker) {
        this.picker.destroy();
      }
    },
    mounted() {
      this.setupPikaday();
    },
    methods: {
      setupPikaday() {
        this.$nextTick(() => {
          this.picker = new Pikaday({
            field: document.getElementById(`datepicker${this.jobId}`),
            trigger: document.getElementById(`datepicker-button${this.jobId}`),
            setDefaultDate: true,
            format: RegionalUtils.global_input,
            reposition: false,
            parse(dateString) {
              return moment(dateString, [RegionalUtils.global_input, RegionalUtils.format_date_input]);
            },
            onSelect: this.selectDate
          });
        });
      },
      selectDate() {
        this.selectedDate = this.picker.toString();

        window.Rails.ajax({
          url: `/my_day/jobs/${this.jobId}.json`,
          type: 'PUT',
          dataType: 'json',
          data: `job[deadline]=${this.selectedDate}`,
          success: () => {
            infoNotification('Deadline updated.');
          },
          error: () => {
            errorNotification('Error updating  deadline.');
          }
        });
      }
    }
  };
</script>
