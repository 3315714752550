import { Controller } from 'stimulus';

export default class extends Controller {
  accordionToggle(event) {
    const target = event.target;
    const accordionHeader = target.closest('.c-accordion__header');

    const currentlyActiveAccordion = document.querySelector('.c-accordion.active');
    const currentlyActiveAccordionHeader = currentlyActiveAccordion?.querySelector('.c-accordion__header');
    if(currentlyActiveAccordion && currentlyActiveAccordionHeader !== accordionHeader) {
      currentlyActiveAccordion.classList.toggle('active');
      const currentlyActiveAccordionBody = currentlyActiveAccordion.querySelector('.c-accordion__body');
      currentlyActiveAccordionBody.style.maxHeight = 0;
    }

    this.element.classList.toggle('active');

    const accordionBody = accordionHeader.nextElementSibling;
    if(this.element.classList.contains('active')) {
      accordionBody.style.maxHeight = `${accordionBody.scrollHeight}px`;
    } else {
      accordionBody.style.maxHeight = 0;
    }
  }
}
