// <div data-controller="prevent-button-double-click">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
    click(event) {
        if(!(!event.detail || event.detail == 1)) event.preventDefault(); 
    }
}
