import { Controller } from 'stimulus';
import Notifications from '../mixins/noty';

export default class extends Controller {
  initialize() {
    this.link = this.element.getAttribute('data-link');
  }

  copyToClipboard() {
    navigator.clipboard.writeText(`${window.location.origin}${this.link}`);
    Notifications.methods.showInformation('Link copied to clipboard.');
  }
}
