<template>
  <pixie-select
    v-model="selectedValue"
    allow-empty="true"
    placeholder="No client selected"
    :search="true"
    :options="options"
    :value="selectedValue">
  </pixie-select>
</template>

<script>
  import Notifications from '../../mixins/noty';
  import PixieSelect from '../form/PixieSelect';

  export default {
    components: {
      PixieSelect
    },
    mixins: [Notifications],
    props: {
      value: {
        type: String,
        default: ''
      },
      emptyValue: {
        type: String,
        default: 'No client selected'
      }
    },
    data() {
      return {
        options: [],
        selectedValue: (this.value == null ? '' : String(this.value))
      };
    },
    watch: {
      value(value) {
        this.selectedValue = (value == null ? '' : String(value));
      },
      selectedValue(value) {
        this.$emit('input', value);
      }
    },
    mounted() {
      this.fetchClientList();
    },
    methods: {
      loadClientOptions(data) {
        const emptyOption = { id: '', description: this.emptyValue };

        const clientOptions = data.map(client => {
          return {
            id: String(client.id),
            description: client.name,
            selected: String(client.id) === String(this.selectedValue)
          };
        });

        this.options = [emptyOption, ...clientOptions];
      },
      fetchClientList() {
        try {
          window.Rails.ajax({
            url: '/clients/list_simple.json?include_archived=1',
            type: 'GET',
            dataType: 'json',
            success: this.loadClientOptions,
            error: error => {
              throw new Error(error);
            }
          });
        }
        catch(error) {
          this.logError(error);

          this.showError('Error loading the client list.', 'Retry', () => {
            this.fetchClientList();
          });
        }
      }
    }
  };
</script>
