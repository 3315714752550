import { Controller } from 'stimulus';
import Awesomplete from 'awesomplete';

export default class extends Controller {
  initialize() {
    const self = this;

    if (self.element.hasAttribute('data-autocomplete-multiple')) {
      new Awesomplete(self.element, {
        minChars: 1,
        autoFirst: true,
        filter: function(text, input) {
          return Awesomplete.FILTER_CONTAINS(text, input.match(/[^ ]*$/)[0]);
        },
        item: itemSelector,
        replace: function(text) {
          const itemProps = JSON.parse(text.value);
          const before = this.input.value.match(/^.+ \s*|/)[0];
          if(before === '') {
            this.input.value = before + itemProps.email + ' ';
          } else {
            this.input.value = before + ', ' + itemProps.email + ' ';
          }
        }
      });
    } else {
      new Awesomplete(self.element, {
        minChars: 1,
        item: itemSelector,
        replace: function(text) {
          const itemProps = JSON.parse(text.value);
          this.input.value = itemProps.email;
        }
      });
    }

    function itemSelector(text, input) {
      return Awesomplete.$.create('li', {
        innerHTML: createItem(text, input),
        'aria-selected': 'false'
      });
    }

    function createItem(text/*, input*/) {
      const itemProps = JSON.parse(text.value);

      const itemContainer = document.createElement('div');
      itemContainer.dataset.flout = 'row-- nowrap-- middle--';

      const itemImg = document.createElement('img');
      itemImg.className = 'c-thumb c-thumb--secondary c-thumb--xxs u-mr';
      itemImg.src = itemProps.avatar_url;

      const itemTextContainer = document.createElement('div');

      const itemRole = document.createElement('p');
      itemRole.className = 'u-ts-4';
      itemRole.innerHTML = itemProps.name;

      const itemEmail = document.createElement('p');
      itemEmail.className = 'u-ts-5';
      itemEmail.innerHTML = itemProps.email;

      itemTextContainer.appendChild(itemRole);
      itemTextContainer.appendChild(itemEmail);

      itemContainer.appendChild(itemImg);
      itemContainer.appendChild(itemTextContainer);

      return itemContainer.outerHTML;
    }

  }
}
