// in case if page has more than one file input use [data-upload-id="<id>"] 
// with input and preview block to define correct element to preview files

import { Controller } from 'stimulus';
const _ = require('lodash/core');

export default class extends Controller {
  connect() {
    if(this.element.getAttribute('data-js') == 'upload-button-input') {
      const labelId = this.element.getAttribute('data-upload-id');

      let label;
      if(labelId) {
        label = document.querySelector(`[data-js="upload-button-preview"][data-upload-id="${labelId}"]`);
      } else {
        label = document.querySelector('[data-js="upload-button-preview"]');
      }

      this.element.addEventListener('change', function(e) {
        let fileName = '';

        this.files ? label.classList.remove('u-hidden') : label.classList.add('u-hidden');

        if(this.files && this.files.length > 1)
          fileName = (this.files.length + ' ' + label.getAttribute('data-text'));
        else
          fileName = e.target.value.split('\\').pop();

        if(fileName != null)
          label.querySelector('[data-js="preview-text"]').innerHTML = _.escape(fileName);
        else {
          const labelVal = label.innerHTML;
          label.innerHTML = labelVal;
        }
      });
    } else if(this.element.getAttribute('data-js') == 'upload-button-clear') {
      const label = this.element.closest('[data-js="upload-button-preview"');
      const uploadId = label.getAttribute('data-upload-id');
      const input = document.querySelector(`input[data-upload-id="${uploadId}"]`);

      this.element.addEventListener('click', function() {
        input.value = '';
        label.classList.add('u-hidden');
      });
    }
  }
}
