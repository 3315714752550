var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.selectedDate,
          expression: "selectedDate",
        },
      ],
      attrs: { id: `datepicker${_vm.jobId}`, type: "hidden" },
      domProps: { value: _vm.selectedDate },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.selectedDate = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "u-ts-4 u-color-gris-dark c-button--editable-date",
        attrs: { id: `datepicker-button${_vm.jobId}` },
      },
      [
        _c("span", { staticClass: "date u-va-middle" }, [
          _vm._v(_vm._s(_vm.humanFriendlyDate)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }