<template>
  <a
    :class="{ 'c-button--disabled': isButtonDisabled }"
    :data-controller="dataControllerBinding"
    data-turbolinks="false"
    data-flitem="shy-left--"
    class="c-button c-button--primary"
    title="There is already a new task in progress"
    href="#"
    @click.prevent="showSimpleTaskForm">
    + Add task
  </a>
</template>
<script>
  import Pixie from '../../shared/globals';

  export default {
    data() {
      return {
        isButtonDisabled: false,
        dataControllerBinding: ''
      };
    },
    created() {
      this.bindEvents();
    },
    methods: {
      showSimpleTaskForm() {
        if (this.isButtonDisabled) {
          return;
        }

        Pixie.EventBus.$emit('pixie:tasks:add_new:click', 'show');
        this.disableButton();
      },
      enableButton() {
        this.isButtonDisabled = false;
        this.dataControllerBinding = '';
      },
      disableButton() {
        this.dataControllerBinding = 'dropdown';
        this.isButtonDisabled = true;
      },
      bindEvents() {
        Pixie.EventBus.$on('pixie:tasks:add_new:cancel', () => this.enableButton());
      }
    }
  };
</script>
