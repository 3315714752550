/* global Chargebee */
import axios from 'axios';
import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    this.chargebee = null;
    this.retries = 0;
    this.activateImmediately = (this.element.dataset.cbActivate == 'true');
    this.customSuccessLocation = this.element.dataset.cbSuccessLocation;
    this.loadChargebee();
  }

  logRetryError(what) {
    const error = `Checkout failure, ${what} did not load`;

    if (window.Sentry) {
      window.Sentry.captureMessage(error, 'error');
    } else {
      console.error(error); // eslint-disable-line no-console
    }
  }

  loadChargebee() {
    this.retries++;

    if (this.retries == 10) {
      /* waited 10 seconds more than enough time to call it quits */
      this.logRetryError('Chargebee');
      return;
    }

    /* when chargebee is not loaded, wait for a bit and retry */
    if (!window.Chargebee) {
      setTimeout(() => {
        this.loadChargebee();
      }, 1000);
      return;
    }

    this.chargebee = Chargebee.init({
      site: this.element.dataset.cbSite
    });
  }

  checkoutPlan(e) {
    if (this.chargebee == null) {
      if (this.retries == 10) {
        location.reload();
      }
      return;
    }

    const plan = e.target.dataset.cbPlanId;
    this.chargebee.openCheckout({
      hostedPage: () => {
        return axios.post('/subscriptions/generate_checkout_url.json',
                    { plan_id: plan, activate_now: this.activateImmediately })
                    .then((response) => response.data);
      },
      loaded: () => {},
      error: (/*error*/) => {},
      step: (/*step*/) => {},
      success: (/*hostedPageId*/) => {
        if (this.activateImmediately) {
          window.location = this.customSuccessLocation ? this.customSuccessLocation : '/subscription_activated';
        }
      },
      close: () => {}
    });
  }

  showPortal() {
    if (this.chargebee == null) return;

    this.chargebee.setPortalSession(() => {
      return axios.get('/subscriptions/create_portal_session')
                  .then((response) => response.data);
    });

    const cbPortal = this.chargebee.createChargebeePortal();
    cbPortal.open();
  }

  showHistory() {
    if (this.chargebee == null) return;

    this.chargebee.setPortalSession(() => {
      return axios.get('/subscriptions/create_portal_session')
                  .then((response) => response.data);
    });

    const cbPortal = this.chargebee.createChargebeePortal();
    cbPortal.open({}, {
      sectionType: Chargebee.getPortalSections().BILLING_HISTORY
    });
  }

  showEditSubscription() {
    if (this.chargebee == null) return;

    this.chargebee.setPortalSession(() => {
      return axios.get('/subscriptions/create_portal_session')
                  .then((response) => response.data);
    });

    const cbPortal = this.chargebee.createChargebeePortal();
    cbPortal.open({}, {
      sectionType: Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
      params: {
        subscriptionId: window.PixieData.subscriptionId
      }
    });
  }
}
