import Vue from 'vue/dist/vue.esm.js';

Vue.directive('click-outside', {
  bind: function(el, binding) {
    
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble;
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e);
      }
    };
    el.__vueClickOutside__ = handler;

    // add Event Listeners
    document.addEventListener('mousedown', handler);
  },
  
  unbind: function(el) {
    // Remove Event Listeners
    document.removeEventListener('mousedown', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  }
});