import axios from 'axios';
import { Controller } from 'stimulus';
import Logger from '../mixins/logger';
import * as Constants from '../shared/constants';
import { infoNotification, errorNotification } from '../components/notifications';

export default class extends Controller {
  static targets = ['inputbutton', 'inputwrapper', 'input', 'subtask', 'list'];

  connect() {
    this.subtaskText = '';
    this.showInput = !this.inputwrapperTarget.classList.contains('u-hidden');
    this.taskId = this.data.get('task-id');
    this.isSubmitting = false;
  }

  toggleInput() {
    this.showInput = !this.showInput;

    if (this.showInput) {
      this.inputTarget.classList.remove('is-error');
      this.inputbuttonTarget.classList.add('u-hidden');
      this.inputwrapperTarget.classList.remove('u-hidden');
      this.inputTarget.focus();
    } else {
      this.inputbuttonTarget.classList.remove('u-hidden');
      this.inputwrapperTarget.classList.add('u-hidden');
      this.subtaskText = '';
      this.inputTarget.value = '';
    }
  }

  setSubtaskText(event) {
    const { value } = event.target;

    this.subtaskText = value;

    if (this.subtaskTextIsValid()) {
      this.inputTarget.classList.remove('is-error');
    }
  }

  subtaskTextIsValid() {
    return this.subtaskText.length > 0;
  }

  lastSubtaskPosition() {
    if (this.subtaskTargets.length === 0) {
      return 0;
    }

    const numbers = this.subtaskTargets.map(subtask => subtask.dataset['number']);

    return Math.max(...numbers);
  }

  newSubtask() {
    return {
      job_id: this.taskId,
      type: 'Job::' + Constants.TASK_TYPE_INSTRUCTON,
      position: this.lastSubtaskPosition() + 1,
      name: this.subtaskText,
      properties: { instructions: '' }
    };
  }

  createSubtask() {
    if (this.subtaskTextIsValid() && !this.isSubmitting) {
      this.isSubmitting = true;

      axios.post(
        '/my_day/tasks/' + this.taskId + '/subtasks', { subtask: this.newSubtask() }
      ).then(response => {
        if (response.status >= 200) {
          const { html } = response.data;
          this.toggleInput();
          this.listTarget.insertAdjacentHTML('beforeend', html);
          infoNotification('Subtask added.');
        }
        this.isSubmitting = false;
      }).catch(error => {
        Logger.methods.logError(error);
        errorNotification('Error creating task.');
        this.isSubmitting = false;
      });
    } else {
      this.addWarning();
    }
  }

  addWarning() {
    this.inputTarget.classList.add('is-error');
  }

  keyStroke(event) {
    // ENTER - submit the filename change
    if (event.keyCode === 13) {
      this.createSubtask();
    }
    // ESC - cancel the filename change
    if (event.keyCode === 27) {
      this.toggleInput();
    }
  }
}
