import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['show', 'unhide'];

  toggle() {
    if (this.value.classList.contains('c-icon--show--gris')) {
      this.input.type = 'text';
      this.value.classList.remove('c-icon--show--gris', 'c-icon--show--gris-dark@hover');
      this.value.classList.add('c-icon--hide--gris', 'c-icon--hide--gris-dark@hover');
    } else {
      this.input.type = 'password';
      this.value.classList.remove('c-icon--hide--gris', 'c-icon--hide--gris-dark@hover');
      this.value.classList.add('c-icon--show--gris', 'c-icon--show--gris-dark@hover');
    }
  }

  get value() {
    return this.showTarget;
  }

  get input() {
    return this.unhideTarget;
  }
}
