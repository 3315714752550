// <div data-controller="confirm" data-confirm-title="" data-confirm-body="">
// </div>

import { Controller } from 'stimulus';
import Noty from 'noty';
import axios from 'axios';

export default class extends Controller {
  initialize() {
    this.element.addEventListener('click', this.confirm.bind(this));

    this.async = this.element.dataset.async;

    // We need to have an ID in order to be able to reference the right element. Most confirm
    // dialogs come from tippy dropdowns, that will duplicate the DOM nodes but will then be deleted
    // as soon as the item is clicked. The ID is the only safe way we have to reference the original
    // DOM element.
    if (!this.element.id) {
      throw('You need to have an ID in your main element when using confirm controller!', this.element);
    }
  }

  confirm(event) {
    if (this.element.dataset.confirmed == 1) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const submitButton = this.element;
    const { icon, confirmTitle, confirmBody } = submitButton.dataset;

    const message = `<div class='o-ratio u-w-1/12 u-mb c-icon c-icon--m ${icon || 'u-hidden'}'></div> <h1 class="c-heading-1 u-color-gris-dark u-mb-large">${confirmTitle || ''}</h1><p class="u-ts-3 u-mb-double u-color-gris-dark">${confirmBody || ''}</p>`;
    const buttons = [
      Noty.button('Cancel', 'c-button c-button--neutral c-button--large u-mr', () => {
        notyInstance.close();
      }),

      Noty.button('Confirm', 'c-button c-button--primary c-button--large u-mr', () => {
        const buttons = document.querySelectorAll(`#${this.element.id}`);
        const buttonInTippy = [...buttons].find(button => button.closest('form.button_to'));

        // We can't just use submitButton here, because it can be the duped one that was generated
        // when the tippy dialog opens (check the comments in the initialize method).
        if (buttonInTippy) {
          const form = buttonInTippy.closest('form.button_to');
          this.confirmationProcess(form);
        } else {
          this.element.dataset.confirmed = 1;

          if (this.element.id.includes('link__')) {
            // When the link is not inside a tippy then we can just click on it (no duplicates were
            // created).
            this.element.click();
          } else {
            // When the link is not inside a tippy, then we can submit the form.
            // According to the HTML standards, if the form is not associated
            // to the browsing context(document), the form submission will be aborted
            // document.body.appendChild(form) - is a workaround.
            // https://stackoverflow.com/questions/42053775/getting-error-form-submission-canceled-because-the-form-is-not-connected

            const form = this.element.closest('form');
            document.body.appendChild(form);
            this.confirmationProcess(form);
          }
        }

        notyInstance.close();
      }, { id: 'button1', 'data-status': 'ok' }),

      Noty.button('', 'c-dialog__close c-button--icon c-icon--rounded-filled-close--gris-dark c-icon--m', () => {
        notyInstance.close();
      })
    ];

    const notyInstance = new Noty({
      text: message,
      layout: 'modal',
      type: 'modal',
      theme: 'modal',
      modal: true,
      killer: true,
      closeWith: 'button',
      animation: {
        open: null,
        close: null
      },
      callbacks: {
        onShow: function() {
          document.querySelector('.noty_modal').addEventListener('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
          });
          document.querySelector('.noty_layout').addEventListener('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
          });
        }
      },
      buttons: buttons
    }).show();

    return false;
  }

  confirmationProcess(form) {
    this.async ? this.sendData(form) : form.submit();
  }

  sendData(form) {
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const formData = new FormData(form);

    axios.post(action, formData);
    axios({
      method: method,
      url: action,
      data: formData
    }).then(this.responseProcess);

  }

  responseProcess(response) {
    // Remove job row from table
    const el = document.getElementById(`job-row-${response.data.id}`);
    el.parentNode.removeChild(el);

    // Render user notification that task was completed
    new Noty({
      type: 'info noty_content',
      text: `<p>${response.data.notice}</p>`,
      timeout: 5000,
      progressBar: false,
      buttons: [
        Noty.button('', 'noty_btn noty_btn--close')
      ]
    }).show();
  }
}
