var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { disabled: _vm.loading },
      on: { click: _vm.onClick },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.buttonLabel) + "\n  "),
      _vm.loading
        ? _c("span", {
            staticClass: "c-loader c-loader--xs c-loader--gris u-ml",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }