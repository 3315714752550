import Noty from 'noty';

/* consistent with flash_helper.rb */
const DEFAULT_TIME = 5000;
const ERROR_TIMEOUT = 10000;

const actionButtons = (label, callback) => {
  if (label && typeof callback === 'function') {
    return [Noty.button(label, 'noty_btn u-t-u u-pointer', callback, { id: 'button1', 'data-status': 'ok' })];
  }

  return [];
};

const infoNotification = (message, actionLabel, actionCallback) => {
  showNotification(message, 'info', DEFAULT_TIME, actionButtons(actionLabel, actionCallback));
};

const errorNotification = (message, actionLabel, actionCallback) => {
  showNotification(message, 'error', ERROR_TIMEOUT, actionButtons(actionLabel, actionCallback));
};

const undoNotification = (message, callback) => {
  const buttons = [];

  if (typeof callback === 'function') {
    buttons.push(Noty.button('Undo', 'noty_btn noty_btn--undo', callback));
  }

  showNotification(message, 'info', DEFAULT_TIME, buttons);
};

const showNotification = (message, type, timeout, buttons = []) => {
  buttons.push(Noty.button('', 'noty_btn noty_btn--close'));

  new Noty({
    type: `${type} noty_content`,
    text: `<p>${message}</p>`,
    progressBar: false,
    timeout: timeout,
    buttons: buttons
  }).show();
};

const requestUserConfirmation = (message, onConfirmationCb, icon, confirmLabel, cancelLabel) => {

  const msg = `<div class='o-ratio u-w-1/12 u-mb-kilo c-icon ${ icon || 'u-hidden' }'></div> <h1 class="c-heading-1 u-color-gris-dark u-mb-double">${ message || '' }</h1>`;

  const n = new Noty({
    text: msg,
    layout: 'modal',
    type: 'modal',
    theme: 'modal',
    modal: true,
    killer: true,
    closeWith: 'button',
    animation: {
      open: null,
      close: null
    },
    callbacks: {
      onShow: function() {
        document.querySelector('.noty_modal').addEventListener('click', function(e) {
          e.stopImmediatePropagation();
          e.stopPropagation();
        });
        document.querySelector('.noty_layout').addEventListener('click', function(e) {
          e.stopImmediatePropagation();
          e.stopPropagation();
        });
      }
    },
    buttons: [
      Noty.button(confirmLabel || 'Confirm', 'c-button c-button--primary u-mr-double', function () {
        try {
          onConfirmationCb && onConfirmationCb();
        }
        catch(e) {/*do nothing*/}

        n.close();
      }),

      Noty.button(cancelLabel || 'Cancel', 'c-button c-button--neutral c-button--faded u-ml-double', function () {
        n.close();
      }),

      Noty.button('', 'c-dialog__close c-button--icon c-icon--rounded-filled-close--gris-dark c-icon--m', function () {
        n.close();
      })

    ]
  }).show();

};

export { infoNotification, errorNotification, undoNotification, requestUserConfirmation };
