/* eslint-disable no-console */
export default {
  methods: {
    logError(error) {
      try {
        if (window.Sentry)
          window.Sentry.captureMessage(error, 'error');
        else
          console.error(error);
      } catch(e) {
        console.error(error);
      }
    },
    logException(exception) {
      try {
        if (window.Sentry)
          window.Sentry.captureException(exception);
        else
          console.error(exception);
      } catch(e) {
        console.error(exception);
      }
    },
    logWarning(message) {
      try {
        if (window.Sentry)
          window.Sentry.captureMessage(message, 'warning');
        else
          console.log(message);
      } catch(e) {
        console.log(message);
      }
    }
  }
};
/* eslint-enable no-console */
