var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "c-table__row--inline u-col-12",
      class: { "is-active": _vm.formVisible },
      attrs: {
        action: _vm.formAction,
        "accept-charset": "UTF-8",
        method: "post",
        enctype: "multipart/form-data",
      },
    },
    [
      _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-table__cell c-table__cell--flex-35 u-pl-large u-pr c-table__row--job",
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.taskName,
                expression: "taskName",
              },
            ],
            ref: "taskNameInput",
            staticClass: "c-input c-input--primary",
            attrs: {
              required: "required",
              placeholder: "Task name",
              type: "text",
              name: "task[name]",
              autocomplete: "off",
            },
            domProps: { value: _vm.taskName },
            on: {
              keyup: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.createSimpleTask.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.cancelSimpleTaskCreation.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.taskName = $event.target.value
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-table__cell c-table__cell--flex-25" },
        [
          _c("client-list-select", {
            model: {
              value: _vm.taskClientId,
              callback: function ($$v) {
                _vm.taskClientId = $$v
              },
              expression: "taskClientId",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-table__cell c-table__cell--flex-25" }, [
        _vm._v("\n    ---\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "c-table__cell c-table__cell--flex-25" },
        [
          _c("vue-pikaday", {
            staticClass: "c-input c-input--primary",
            attrs: {
              options: _vm.pikadayOptions,
              placeholder: "Pick a date",
              name: "task[deadline]",
            },
            model: {
              value: _vm.taskDeadline,
              callback: function ($$v) {
                _vm.taskDeadline = $$v
              },
              expression: "taskDeadline",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "c-table__cell c-table__cell--flex-20" }, [
        _vm._v("\n    ---\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-table__cell c-table__cell--flex-30 c-table__cell--actions u-pl u-pv-none",
          attrs: { "data-flout": "end--" },
        },
        [
          _c(
            "button",
            {
              staticClass: "c-button c-button--neutral u-mr",
              attrs: { type: "button", disabled: _vm.submitting },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.cancelSimpleTaskCreation.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("labels.actions.cancel")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("loading-button", {
            attrs: {
              classes: ["c-button", "c-button--blue"],
              loading: _vm.submitting,
              text: _vm.$t("labels.actions.save_task"),
              "text-loading": _vm.$t("labels.actions.saving_task"),
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.createSimpleTask.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }