/* global ActiveStorage */
import { Controller } from 'stimulus';
import { errorNotification } from '../components/notifications';

export default class extends Controller {
  static targets = ['file', 'button', 'isupload'];

  connect() {

    if(typeof this.element == 'undefined') return;

    const self = this;

    this.reset();

    document.querySelectorAll('[data-js=\'close-modal\']').forEach(function(e) {
      e.onclick = function() {
        self.buttonTarget.classList.remove('is-error');
      };
    });

    this.fileTarget.onchange = function(event) {
      self.onFilesSelected(event, this);
    };

  }

  reset() {

    this.remainingFiles = 0;
    this.buttonTarget.classList.remove('is-hover');
    this.buttonTarget.style.display = '';
    this.isuploadTarget.classList.add('u-hidden');

  }

  onFilesSelected(event, input) {

    this.buttonTarget.style.display = 'none';
    this.isuploadTarget.classList.remove('u-hidden');

    const form = this.fileTarget.closest('form');
    const self = this;

    this.remainingFiles = input.files.length;

    if (input.dataset.directUploadUrl == null) {
      /* not a direct upload */
      form.submit();

    } else {

      /* using direct uploads */
      Array.from(input.files).forEach(function(file) {

        const url = input.dataset.directUploadUrl;
        const upload = new ActiveStorage.DirectUpload(file, url);

        upload.create(function(error, blob) {
          if (error) {
            errorNotification(`Failed to upload: ${file.name}`);
          } else {
            const hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('value', blob.signed_id);
            hiddenField.name = input.name;
            form.appendChild(hiddenField);
          }

          self.remainingFiles--;

          if (self.remainingFiles == 0) {
            input.value = null;
            form.submit();
          }
        });

      });

    }

  }

  startHover(event) {
    event.preventDefault();
    this.buttonTarget.classList.add('is-hover');
  }

  endHover(event) {
    event.preventDefault();
    this.buttonTarget.classList.remove('is-hover');
  }

}
