import { Controller } from 'stimulus';

export default class extends Controller {
  prefillEmail(event) {
    const blob = JSON.parse(event.currentTarget.dataset.blobJson);
    const filename = blob.filename;
    const key = blob.key;
    const file = new File([blob], filename);
    const emailData = {
      attachments: [{ file, key }],
      subject: 'Sharing a file with you'
    };

    window.dispatchEvent(new CustomEvent('emailModalData', { detail: emailData }));
  }
}
