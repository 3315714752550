import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['links', 'description', 'addsubtask'];

  keyStroke(event) {
    // ENTER - submit the filename change
    if (event.keyCode === 13) {
      this.createSubtask();
    }
    // ESC - cancel the filename change
    if (event.keyCode === 27) {
      this.toggleInput();
    }
  }
}
