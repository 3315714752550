import { Controller } from 'stimulus';

export default class extends Controller {
  /*
    the removeFields function is only used in the Settings pages for Client fields, Contact fields, and Task tags.

    these items are only actually removed when the user clicks Save
   */
  removeFields(event) {
    const target = event.target;
    const fieldset = target.closest('fieldset');
    fieldset.querySelector('input[id*=destroy]').value = '1';
    fieldset.style.opacity = '0.2';
    fieldset.style.cursor = 'not-allowed';

    fieldset.querySelectorAll('input:not([type="hidden"]),button,a,.px-select-container').forEach((el) => {
      el.disabled = 'disabled';
      el.style.cursor = 'not-allowed';
    });
    fieldset.querySelectorAll('div[class*="icon--trash"]').forEach((el) => { el.style.display = 'none'; });

    if (fieldset.querySelectorAll('input:invalid').length > 0) {
      fieldset.parentElement.removeChild(fieldset);
    }

    event.preventDefault();
  }

  addFields(event) {
    const target = event.target;
    const time = new Date().getTime();
    const regexp = new RegExp(target.dataset.id, 'g');
    const fields = target.dataset.fields;

    const newElem = document.createElement('div');
    newElem.innerHTML = fields.replace(regexp, time);

    if (event.target.dataset.customInsert == null) {
      target.insertAdjacentElement('beforebegin', newElem.firstChild);
    } else {
      const parent = document.querySelector(event.target.dataset.customInsert);
      parent.appendChild(newElem.firstChild);
      parent.classList.remove('u-hidden');
    }

    event.preventDefault();
  }
}
