// <div data-controller="validator">
// </div>

import { Controller } from 'stimulus';
import hyperform from 'hyperform';

export default class extends Controller {
  formValidator = null;

  initialize() {
    hyperform.setRenderer('attachWarning', function(warning, element) {
      const parent = element.parentNode;

      if (parent.querySelectorAll('.c-input-label--warning').length > 0) {
        return;
      }

      switch (element.nodeName) {
        case 'SELECT':
          parent.parentNode.appendChild(warning);
          parent.classList.add('is-error');
          parent.querySelector('.px-select-selected').focus();
          break;
        case 'INPUT':
          if (element.type == 'radio') {
            const table = element.closest('table');

            if (table == null) {
              if (parent.classList.contains('is-error')) {
                return;
              }

              warning.classList.add('u-w-100');
              warning.classList.add('u-pb-double');
              parent.insertBefore(warning, parent.firstChild);
              parent.classList.add('is-error');
            } else {
              if (table.parentNode.classList.contains('is-error')) {
                return;
              }

              warning.classList.add('u-p');
              table.parentNode.insertBefore(warning, table);
              table.parentNode.classList.add('is-error');
            }
          } else {
            parent.appendChild(warning);
          }
          break;
        default:
          parent.appendChild(warning);
          break;
      }
    });

    if (this.element.dataset.custom) {
      const teamAddresses = window.PixieData ? window.PixieData.teamAddresses : [];

      switch(this.element.dataset.custom) {
        case 'at-least-one':
          hyperform.addValidator(this.element, element => {
            const selects = [...document.querySelectorAll('[data-custom="at-least-one"]')];
            const values = selects.map(select => select.value);
            const valid = values.filter(value => value !== '').length > 0;

            element.setCustomValidity(valid ? '' : 'Please select at least one option.');

            return valid;
          });
          break;
        case 'team-email':
          hyperform.addValidator(this.element, element => {
            const valid = !teamAddresses.includes(element.value?.toLowerCase());

            element.setCustomValidity(valid ? '' : 'This email address belongs to a team member.');

            return valid;
          });
          break;
      }
    }
  }

  connect() {
    this.formValidator = hyperform(this.element, {
      preventImplicitSubmit: true,
      extendFieldset: false,
      validateNameless: true,
      classes: {
        valid: 'is-valid',
        invalid: 'is-error',
        warning: 'c-input-label c-input-label--warning',
      }
    });
  }

  disconnect() {

    if (this.formValidator == null) {
      return;
    }

    this.formValidator.destroy();
    this.formValidator = null;
  }
}
