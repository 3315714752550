import moment from 'moment-timezone';
import i18next from 'i18next';
import RegionalUtils from './regional_utils';

export const temporalUnitOptions = [
  {
    id: 'day',
    value: 'day',
    description: i18next.t('labels.job_templates.days_after_job_starts'),
    label: i18next.t('labels.job_templates.days_after_job_starts')
  },
  {
    id: 'week',
    value: 'week',
    description: i18next.t('labels.job_templates.weeks_after_job_starts'),
    label: i18next.t('labels.job_templates.weeks_after_job_starts')
  },
  {
    id: 'month',
    value: 'month',
    description: i18next.t('labels.job_templates.months_after_job_starts'),
    label: i18next.t('labels.job_templates.months_after_job_starts')
  },
  {
    id: 'day_before_deadline',
    value: 'day_before_deadline',
    description: i18next.t('labels.job_templates.days_before_job_deadline'),
    label: i18next.t('labels.job_templates.days_before_job_deadline')
  },
  {
    id: 'week_before_deadline',
    value: 'week_before_deadline',
    description: i18next.t('labels.job_templates.weeks_before_job_deadline'),
    label: i18next.t('labels.job_templates.weeks_before_job_deadline')
  },
  {
    id: 'month_before_deadline',
    value: 'month_before_deadline',
    description: i18next.t('labels.job_templates.months_before_job_deadline'),
    label: i18next.t('labels.job_templates.months_before_job_deadline')
  },
];

export const formatDateWithTimezone = (timestamp, format =  RegionalUtils.format_date_time) => {
  const timezone = document.head.querySelector('meta[name="timezone"]').content;
  const unix = moment.unix(timestamp);

  return moment(unix, timezone).format(format);
};

export default { formatDateWithTimezone };
