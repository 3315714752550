import hyperform from 'hyperform';

/* You must implement getValidationForm in the component if the form is not this.$el! */
export default {
  computed: {
    hyperform() {
      return hyperform;
    }
  },
  data() {
    return {
      formValidator: null
    };
  },
  methods: {
    attachRenderer() {
      hyperform.setRenderer('attachWarning', (warning, element) => {
        switch (element.nodeName) {
          case 'SELECT':
            element.parentNode.classList.add('is-error');
            break;
          case 'TEXTAREA': {
            const prevSibling = element.previousElementSibling;

            if (prevSibling.classList.contains('c-rich-editor')) {
              prevSibling.classList.add('is-error');
            }
            break;
          }
          case 'INPUT':
            // This is the hidden field of a Vue PixieSelect component. We need to add the class to
            // the select div of the component and attach the warning to the root element.
            if (element.classList.contains('pixie-select-hfield')) {
              const pixieSelect = element.previousElementSibling;
              pixieSelect.classList.add('is-error');
            }
            break;
        }
        if (element.closest('fieldset, div')) {
          element.closest('fieldset, div').appendChild(warning);
        }
      });

      hyperform.setRenderer('detachWarning', (warning, element) => {
        switch (element.nodeName) {
          case 'SELECT':
            element.parentNode.classList.remove('is-error');
            break;
          case 'TEXTAREA': {
            const prevSibling = element.previousElementSibling;

            if (prevSibling.classList.contains('is-error')) {
              prevSibling.classList.remove('is-error');
            }
            break;
          }
          case 'INPUT':
            // This is the hidden field of a Vue PixieSelect component. We need to add the class to
            // the select div of the component and attach the warning to the root element.
            if (element.classList.contains('pixie-select-hfield')) {
              const pixieSelect = element.previousElementSibling;
              pixieSelect.classList.add('is-error');
            }
            break;
        }

        warning.remove();
      });
    },
    bindFormValidator() {
      this.attachRenderer();

      // If you want to have another form to be validated then implement getValidationForm
      // in the component.
      let form = this.$el;

      if (this.getValidationForm) {
        form = this.getValidationForm();
      }

      this.formValidator = hyperform(form, {
        preventImplicitSubmit: true,
        extendFieldset: false,
        validateNameless: true,
        classes: {
          valid: 'is-valid',
          invalid: 'is-error',
          warning: 'c-input-label c-input-label--warning',
        }
      });
    },
    destroyFormValidator() {
      this.formValidator.destroy();
    },
    validForm(inner) {
      const form = (inner === true ? this.$el.querySelector('form') : this.$el.closest('form'));

      if (form.reportValidity()) {
        return true;
      }

      form.querySelector('.is-error').focus();
    }
  }
};
