// <div data-controller="datepicker">
// </div>

import { Controller } from 'stimulus';
import Pikaday from 'pikaday';
import moment from 'moment';
import axios from 'axios';
import Noty from 'noty';
import RegionalUtils from '../components/utils/regional_utils';

export default class extends Controller {
  picker = null;
  tempField = null;

  connect() {
    this.element.dataset.unsnooze ? this.processClick() : this.processDatepicker();
  }

  processClick() {
    this.element.addEventListener('click', (e) => {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();

      this.sendData(null);
    });
  }

  processDatepicker() {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear()+1);

    this.async = this.element.dataset.async;

    if (this.tempField == null) {
      this.tempField = document.createElement('input');
      this.tempField.setAttribute('type', 'hidden');
      this.element.parentNode.insertBefore(this.tempField, this.element);
    }

    this.picker = new Pikaday({
      field: this.tempField,
      trigger: this.element,
      format: RegionalUtils.global_input,
      firstDay: 1,
      minDate: tomorrow,
      maxDate: maxDate,
      numberOfMonths: 1,
      theme: 'pixie-job-snooze',
      parse(dateString) {
        return moment(dateString, [RegionalUtils.global_input, RegionalUtils.format_date_input]);
      },
      onSelect: () => {
        const date = this.picker.toString(RegionalUtils.global_input);
        this.async ? this.sendData(date) : this.submitForm(date);

      }
    });
  }

  sendData(date) {
    const action = `/my_day/jobs/${this.element.dataset.jobId}/snooze`;
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const formData = new FormData();
    if(date) formData.append('snooze', date);
    formData.append('authenticity_token', csrf);

    axios.post(action, formData).then(this.responseProcess);
  }

  responseProcess(response) {
    // Remove job row from table
    const el = document.getElementById(`job-row-${response.data.id}`);
    el.parentNode.removeChild(el);

    // Render user notification that task was completed
    const notice = response.data.notice;
    new Noty({
      type: 'info noty_content',
      text: `<p>${notice}</p>`,
      timeout: 5000,
      progressBar: false,
      buttons: [
        Noty.button('', 'noty_btn noty_btn--close')
      ]
    }).show();
  }

  submitForm(date) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = `/my_day/jobs/${this.element.dataset.jobId}/snooze`;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'snooze';
    hiddenField.value = date;
    form.appendChild(hiddenField);

    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const csrfField = document.createElement('input');
    csrfField.type = 'hidden';
    csrfField.name = 'authenticity_token';
    csrfField.value = csrf;
    form.appendChild(csrfField);

    document.body.appendChild(form);
    form.submit();
  }

  disconnect() {
    if (this.tempField != null) {
      this.tempField.parentNode.removeChild(this.tempField);
    }

    if (this.picker == null) {
      return;
    }

    this.picker.destroy();
  }
}
