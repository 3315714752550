export const JOB_TEMPLATE_NAMESPACE = 'JobTemplate';
export const TASK_TYPE_INSTRUCTON = 'TaskInstruction';
export const TASK_TYPE_REQUEST_SIGNATURE = 'TaskRequestSignature';
export const TASK_TYPE_AUTO_EMAIL = 'TaskAutoEmail';
export const TASK_TYPE_MANUAL_EMAIL = 'TaskManualEmail';
export const TASK_TYPE_MILESTONE = 'TaskMilestone';
export const TASK_TYPE_BTC_SELF_ASSESSMENT = 'TaskBtcSelfAssessment';

export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_MONTH = 'month';
export const PERIOD_DEADLINE = 'fixed_deadline';

export const PERIOD_OPTIONS = {};
PERIOD_OPTIONS[PERIOD_DAY] = 'Days';
PERIOD_OPTIONS[PERIOD_WEEK] = 'Weeks';
PERIOD_OPTIONS[PERIOD_MONTH] = 'Months';
PERIOD_OPTIONS[PERIOD_DEADLINE] = 'Deadline';

export const TASK_TYPES = {};
TASK_TYPES[TASK_TYPE_INSTRUCTON] = 'Instructions';
TASK_TYPES[TASK_TYPE_REQUEST_SIGNATURE] = 'Document signing - request signature';
TASK_TYPES[TASK_TYPE_AUTO_EMAIL] = 'Send automated email';
TASK_TYPES[TASK_TYPE_MANUAL_EMAIL] = 'Send email manually';
TASK_TYPES[TASK_TYPE_MILESTONE] = 'Internal deadline';
TASK_TYPES[TASK_TYPE_BTC_SELF_ASSESSMENT] = 'BTC self assessment';

export const BTCSOFTWARE_INTEGRATION_STEPS = [
  { 'id': 1, 'label': 'Import clients' },
  { 'id': 2, 'label': 'Import work' },
];

export const BTCSOFTWARE_INTEGRATION_STATUS = {
  INITIAL_STEP: 0,
  IMPORTING_CLIENTS: 1,
  IMPORTING_WORK: 2,
  COMPLETE: 3,
};

// TODO: ideally we wouldn't need to use the type here, but that's how the vuejs code rolls today.
// We should remove the prefix and then change this to just a simple array.
export const TASK_TYPES_OPTIONS = (type) => (
  [
    {
      value: `${type}::TaskInstruction`,
      label: 'Instructions'
    },
    {
      value: `${type}::TaskRequestSignature`,
      label: 'Document signing - request signature'
    },
    {
      value: `${type}::TaskAutoEmail`,
      label: 'Send automated email'
    },
    {
      value: `${type}::TaskManualEmail`,
      label: 'Send email manually'
    },
    {
      value: `${type}::TaskMilestone`,
      label: 'Internal deadline'
    },
    {
      value: `${type}::TaskBtcSelfAssessment`,
      label: 'BTC self assessment'
    }
  ]
);

export const FILTER_OPERATORS = [
  {
    id: 'is',
    description: 'is'
  },
  {
    id: 'is_not',
    description: 'is not'
  }
];

export const AUDIT_LOGS_TYPES = {
  JOB: 'job',
  CLIENT: 'client',
  USER: 'user',
};

// Job templates
export const TRIGGER_DATE = 'JobTemplate::TriggerDate';
export const TRIGGER_WEEKDAY = 'JobTemplate::TriggerWeekDay';
export const TRIGGER_MONTHDAY = 'JobTemplate::TriggerMonthDay';
export const TRIGGER_CUSTOMFIELD = 'JobTemplate::TriggerCustomFieldDate';

export const ADJUST_EXACT_DATE = 'on_day';
export const ADJUST_DAYS_BEFORE = 'days_before';
export const ADJUST_DAYS_AFTER = 'days_after';

export const REPEAT_NONE = '';
export const REPEAT_WEEKLY = 'weekly';
export const REPEAT_MONTHLY = 'monthly';
export const REPEAT_YEARLY = 'yearly';

export const DEADLINE_DAYS_AFTER_START = 'day';
export const DEADLINE_WEEKS_AFTER_START = 'week';
export const DEADLINE_MONTHS_AFTER_START = 'month';
export const FIXED_DEADLINE = 'fixed_deadline';

// Reports
export const DASHBOARD_BREAKPOINTS = {
  tablet: 768,
  desktop: 1200,
};

export const CHART_COLOURS = [
  '#53B700',
  '#0097E6',
  '#E31C9E',
  '#FF8000',
];

export const COUNT_OF_JOBS_PER_STATUS = 'count_of_jobs_per_status';
export const COUNT_OF_JOBS_PER_DEADLINE = 'count_of_jobs_per_deadline';
export const TIMELINES = 'timelines';
export const NUMBER_OF_CLIENTS_PER_EMPLOYEE = 'number_of_clients_per_employee';
export const NUMBER_OF_CLIENTS_IN_QUICKBOOKS_SKUS = 'number_of_clients_in_quickbooks_skus';

export const COUNT_OF_CLIENTS_PER_TEMPLATE = 'count_of_clients_per_template';
