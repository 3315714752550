// <div data-controller="dropdown">
// </div>

import { Controller } from 'stimulus';
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = ['container'];

  tippyInstance = null;

  connect() {
    const trigger = this.element.dataset.trigger || null;

    if (this.containerTargets.length > 0) {
      const tippyPlacement = this.element.dataset.tippyPlacement || 'bottom-end';

      /* is out immediate parent hover-actions? */
      let hoverActions = null;
      if (this.element.parentElement.classList.contains('hover-actions')) {
        hoverActions = this.element.parentElement;
      }

      const content = this.containerTarget.cloneNode(true);

      this.tippyInstance = tippy(this.element, {
        html: content, /* use a clone because it'll be destroyed via _tippy.destroy() */
        arrow: true,
        interactive: true,
        placement: tippyPlacement,
        allowTitleHTML: false,
        trigger: (trigger == null ? 'click focus' : trigger),
        appendTo: (typeof this.element.dataset.tippyAppendToThis == 'undefined') ? document.body : this.element,
        theme: 'primary',
        multiple: false,
        duration: [100, 100],

        onShow: () => {
          content.innerHTML = this.containerTarget.innerHTML;

          if (hoverActions == null) return;
          hoverActions.classList.add('u-visible');
        },
        onShown: (instance) => {
          // Get all dropdown items and close the tippy when these items are clicked
          const listItems = document.querySelectorAll('.c-dropdown__item *');

          listItems.forEach(item => {
            item.addEventListener('click', () => {
              instance.hide();
            });
          });
        },

        onHide: () => {
          if (hoverActions == null) return;
          hoverActions.classList.remove('u-visible');
        },

        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: (typeof this.element.dataset.tippyPreventOverflow == 'undefined')
            },
            hide: {
              enabled: (typeof this.element.dataset.tippyPreventOverflow == 'undefined')
            }
          }
        }
      });

      if (this.element.dataset.tippyPreventClick != null) {
        return;
      }

      this.element.addEventListener('click', (event) => {
        event.preventDefault();
      });
    } else {
      this.tippyInstance = tippy(this.element, {
        arrow: true,
        placement: 'bottom',
        interactive: (this.element.dataset.interactive == null ? false : true),
        appendTo: (typeof this.element.dataset.tippyAppendToThis == 'undefined') ? document.body : this.element,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: (typeof this.element.dataset.tippyPreventOverflow == 'undefined')
            },
            hide: {
              enabled: (typeof this.element.dataset.tippyPreventOverflow == 'undefined')
            }
          }
        },
        trigger: (trigger == null ? 'mouseenter focus' : trigger),
        theme: 'secondary',
        multiple: false,
        duration: [100, 100]
      });
    }
  }

  disconnect() {
    if (this.tippyInstance == null) return;

    this.tippyInstance.tooltips.forEach(tooltip => tooltip.destroy());
    this.tippyInstance = null;
  }
}
