// <div data-controller="state">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'form', 'reference' ]

  submit() {
    const self = this;
    self.element.classList.add('is-active');
    setTimeout(function() {
      self.formTarget.submit();
    }, 0);
  }

  setElementActive(event) {
    const container = document.querySelector('[data-js~="set-active"]');
    container.classList.add('is-active');

    const focusEl = container.querySelector('[data-default-focus]');

    if (focusEl != null) {
      focusEl.focus();
    }

    this.referenceTargets.forEach(e => {
      e.value = event.target.dataset.messageId;
    });
  }
  setElementInactive() {
    document.querySelector('[data-js~="set-active"]').classList.remove('is-active');
  }
}
