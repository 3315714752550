// <div data-controller="attachment-upload">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'input', 'preview', 'format' ]

  connect() {

    if(this.inputTarget.dataset.initialized != null) return;

    if(this.inputTarget.files.length == 0) this.inputTarget.click();
    this.inputTarget.dataset.initialized = true;

  }

  setPreview() {
    if(this.inputTarget.files.length == 1) {
      const file = this.inputTarget.files[0].name;
      this.previewTarget.innerText = file.substring(0, file.lastIndexOf('.'));
      this.formatTarget.innerText = file.substring(file.lastIndexOf('.') + 1);
    } else {
      this.previewTarget.innerText = this.inputTarget.files.length + ' files';
      this.formatTarget.innerText = '(' + [...this.inputTarget.files].map(f => f.name).join(', ') + ')';
    }
    this.element.classList.remove('u-hidden');
  }

  removeFieldsPerm(event) {
    const target = event.target;
    const fieldset = target.closest('fieldset');
    fieldset.parentElement.removeChild(fieldset);
    event.preventDefault();
  }
}
