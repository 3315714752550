// <div data-controller="0-day">
// </div>

import { Controller } from 'stimulus';
import Pixie from '../shared/globals';
import CreateSimpleTask from '../components/shared/CreateSimpleTask';
import CreateSimpleTaskButton from '../components/shared/CreateSimpleTaskButton';
import DeadlineEditor from '../components/jobs/DeadlineEditor';

export default class extends Controller {
  vueApp = null;

  connect() {
    // ensure mount element is present
    if(document.querySelector('[data-js~="jobs-listing"]') == null) {
      throw new Error('Task listing element not present');
    }

    this.vueApp = Pixie.createVueApp({
      el: '[data-js~="jobs-listing"]',
      components: {
        'deadline-editor': DeadlineEditor,
        'create-simple-task-button': CreateSimpleTaskButton,
        'create-simple-task': CreateSimpleTask
      },
      data() {
        return {
          filter: 'all',
          empty_state: false
        };
      },
      watch: {
        filter: function (/*val*/) {
          const self = this;
          setTimeout(function() {
            if(document.querySelectorAll('.c-table__row:not(.u-hidden)').length == 0) {
              self.empty_state = true;
            } else {
              self.empty_state = false;
            }
          }, 0);
        }
      },
      methods: {
        checkState(state) {
          if(this.filter == 'all') return true;
          else if(state.indexOf(this.filter) != -1) return true;
          else return false;
        }
      }
    });
  }

  disconnect() {
    if (this.vueApp != null)
      this.vueApp.$destroy();

    this.vueApp = null;
  }
}
