// <div data-controller="datepicker">
// </div>

import { Controller } from 'stimulus';
import Pikaday from 'pikaday';
import IMask from 'imask';
import moment from 'moment';
import RegionalUtils from '../components/utils/regional_utils';
import { formatDateWithTimezone } from '../components/utils/date_utils';

export default class extends Controller {
  static targets = ['input'];
  picker = null;
  mask = null;
  showing = false;

  connect() {
    if (this.inputTarget == null) {
      this.inputTarget = this.element;
    }

    const formattedDefaultDate = formatDateWithTimezone(this.inputTarget.value, RegionalUtils.global_input);
    const manualTrigger = this.element.dataset.trigger == 'manual';

    this.picker = new Pikaday({
      field: this.inputTarget,
      defaultDate: formattedDefaultDate,
      trigger: (manualTrigger ? this.element.querySelector('[data-trigger-button]') : null),
      setDefaultDate: true,
      format: RegionalUtils.global_input,
      parse: dateString => {
        return formatDateWithTimezone(dateString, RegionalUtils.global_input);
      },
      onOpen: () => {
        this.showing = true;
        if (this.inputTarget.value) {
          /*
            this guarantees that the calendar dropdown opens with the correct date pre-selected
            even when the widget is being managed with Vuejs
          */

          return moment(this.inputTarget.value);
        }
      },
      onClose: () => {
        this.showing = false;
      },
      toString: (date, format) => {
        // We can't rely on formatDateWithTimezone because we want to show the date on the timezone
        // of the user.
        return moment(date).format(format);
      },
      onSelect: () => {
        // Imask likes to be notified when the input value changes (or you'll get a warning).
        if (manualTrigger) {
          this.mask.updateValue();
        }
      }
    });

    this.inputTarget.addEventListener('blur', () => {
      if (this.showing) {
        this.picker.hide();
      }
    });

    // When trigger is mannually activate mask
    if (manualTrigger) {
      this.mask = IMask(this.inputTarget, {
        mask: Date,
        pattern: RegionalUtils.global_input,
        lazy: true,
        overwrite: true,
        autofix: true,

        format: function (date) {
          return moment(date).format(RegionalUtils.global_input);
        },
        parse: function (str) {
          return moment(str, RegionalUtils.global_input);
        },

        blocks: {
          YYYY: {
            mask: IMask.MaskedRange,
            from: 1900, // Help users to write between the right interval
            to: 2999,
            maxLength: 4,
            placeholderChar: 'Y'
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            placeholderChar: 'M'
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
            placeholderChar: 'D'
          }
        }
      });
    }
    else {
      return;
    }
  }

  disconnect() {
    if (this.picker == null) {
      return;
    }

    this.picker.destroy();
  }
}
