import { Controller } from 'stimulus';
import Pixie from '../shared/globals';
import { infoNotification, errorNotification } from '../components/notifications';
const _ = require('lodash');

export default class extends Controller {
  static targets = ['filename', 'editForm', 'editButton', 'textField'];

  eventKey = 'pixie:file-rename:start';
  editting = false;
  id = undefined;

  connect() {
    this.key = this.element.dataset.key;

    document.addEventListener('mousedown', (event) => {
      if (this.editButtonTarget.contains(event.target)) {
        return;
      }

      if (!this.editFormTarget.contains(event.target) && this.editting) {
        this.nameUpdate();
      }
    });

    // If the element receives a 'pixie:file-rename:start' and it comes from another element then
    // the edit mode should be switched off.
    Pixie.EventBus.$on(this.eventKey, (key) => {
      if (key !== this.key) {
        this.stopEdit();
      }
    });
  }

  toggleEdit() {
    this.editting = !this.editting;
    this.editting ? this.edit() : this.stopEdit();
  }

  edit(event) {
    if (event) {
      event.preventDefault();
    }

    Pixie.EventBus.$emit(this.eventKey, this.key);

    this.editting = true;
    this.filenameTarget.classList.add('u-hidden');
    this.editFormTarget.classList.remove('u-hidden');
    this.textFieldTarget.value = this.currentBaseName();
    this.textFieldTarget.select();
  }

  stopEdit(event) {
    if (event) {
      event.preventDefault();
    }

    this.editting = false;
    this.filenameTarget.classList.remove('u-hidden');
    this.editFormTarget.classList.add('u-hidden');
  }

  nameUpdate(event) {
    if (event) {
      event.preventDefault();
    }

    const oldName = this.currentFullName();
    const basename = this.textFieldTarget.value;
    const newName = this.buildFullName(basename);

    // Just stop edit and return if the name didn't change or if the user submitted an empty field.
    if (oldName === newName || basename.length == 0) {
      this.stopEdit();
      return;
    }

    this.filenameTarget.innerHTML = '<span class="u-color-blue">Renaming...</span>';
    this.stopEdit();

    const purifiedName = encodeURIComponent(newName);

    // We always wait at least 500ms to avoid flickering when the request is too fast.
    setTimeout(() => window.Rails.ajax({
      url: `/blobs/${this.key}.json`,
      type: 'PUT',
      dataType: 'json',
      data: `name=${escape(purifiedName)}`,
      success: () => {
        infoNotification('Rename complete.');
        this.filenameTarget.innerHTML = _.escape(newName);
      },
      error: () => {
        errorNotification('Rename failed.');
        this.filenameTarget.innerHTML = _.escape(oldName);
      }
    }), 500);
  }

  keyStroke(event) {
    // ENTER - submit the filename change
    if (event.keyCode === 13) {
      this.nameUpdate();
    }
    // ESC - cancel the filename change
    if (event.keyCode === 27) {
      this.stopEdit();
    }
  }

  currentFullName() {
    return _.unescape(this.filenameTarget.innerHTML);
  }

  currentBaseName() {
    const full = this.currentFullName();
    const lastDotPosition = full.lastIndexOf('.');

    // File with no extension
    if (lastDotPosition === -1) {
      return full;
    } else {
      return full.substr(0, lastDotPosition);
    }
  }

  // Returns the current file extension. The dot is included.
  // Example: "my_filename.jpeg" => ".jpeg"
  currentExtension() {
    const full = this.currentFullName();
    const lastDotPosition = full.lastIndexOf('.');

    // File with no extension
    if (lastDotPosition === -1) {
      return '';
    } else {
      return full.substr(lastDotPosition);
    }
  }

  buildFullName(basename) {
    return `${basename}${this.currentExtension()}`;
  }
}
