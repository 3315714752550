// <div data-controller="textarea-expand">
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const savedValue = this.element.value;
    this.element.value = '';
    this.baseScrollHeight = this.element.scrollHeight;
    this.multiplier = parseInt(window.getComputedStyle(this.element, null).getPropertyValue('line-height'));
    this.element.value = savedValue;
    this.minRows = this.element.rows;

    this.element.addEventListener('input', this.update.bind(this));
  }

  update() {
    this.element.rows = this.minRows;
    const newRows = Math.ceil((this.element.scrollHeight - this.baseScrollHeight) / this.multiplier);
    this.element.rows = this.minRows + newRows;
  }
}
