import {
  infoNotification,
  errorNotification,
  undoNotification,
  requestUserConfirmation
} from '../components/notifications';

export default {
  methods: {
    showError: errorNotification,
    showUndo: undoNotification,
    showInformation: infoNotification,
    requestUserConfirmation: requestUserConfirmation
  }
};
