var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "select",
      staticClass: "px-select-container c-selectbox--primary px-select-desktop",
      class: {
        open: _vm.expanded,
        "has-selected": _vm.selectedOption,
        "px-select-disabled": _vm.disabled === true,
        "px-select-container--top": _vm.dropdownPosition.placement === "top",
      },
      staticStyle: { width: "100%" },
    },
    [
      _c(
        "div",
        {
          staticClass: "px-select-selected selectr-selected",
          attrs: {
            disabled: _vm.disabled,
            tabindex: "0",
            "aria-expanded": _vm.expanded,
          },
          on: { click: _vm.toggleOptions },
        },
        [
          _vm.selectedOption
            ? _c("span", { staticClass: "px-select-label selectr-label" }, [
                _vm.selectedOption.src
                  ? _c("img", {
                      staticClass: "c-thumb c-thumb--xxs u-mr",
                      attrs: { src: _vm.selectedOption.src },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "u-va-middle" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedOption.label) +
                      "\n        "
                  ),
                  _vm.selectedOption.sublabel
                    ? _c(
                        "span",
                        { staticClass: "u-ts-5 u-pl-tiny u-color-gris" },
                        [_vm._v(_vm._s(_vm.selectedOption.sublabel))]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "px-select-placeholder" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.selectedValueOrPlaceholder) + "\n    "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "options", staticClass: "px-select-options-container" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              ref: "searchcontainer",
              staticClass: "px-select-input-container",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                ref: "search",
                staticClass:
                  "px-select-input hf-validated is-valid hf-in-range",
                attrs: {
                  tagindex: "-1",
                  autocomplete: "off",
                  autocorrect: "off",
                  autocapitalize: "off",
                  spellcheck: "true",
                  role: "textbox",
                  type: "search",
                  placeholder: "Search options...",
                  tabindex: "0",
                  "aria-invalid": "false",
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    $event.preventDefault()
                    return _vm.toggleOptions.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "px-select-input-clear",
                attrs: { type: "button" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "px-select-notice" }),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "px-select-options",
              attrs: {
                role: "tree",
                "aria-hidden": !_vm.expanded,
                "aria-expanded": _vm.expanded,
              },
            },
            [
              _vm.allowSelectionReset && _vm.value != "" && _vm.value != null
                ? _c(
                    "li",
                    {
                      staticClass: "px-select-option",
                      attrs: { role: "treeitem" },
                      on: {
                        mouseenter: function ($event) {
                          return _vm.onOptionHover({})
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.reset()
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.resetOptionLabel) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.filteredOptions, function (option, index) {
                return _c(
                  "li",
                  {
                    key: `${index}_${option.value}`,
                    staticClass: "px-select-option",
                    class: {
                      selected: option.selected,
                      active: option === _vm.hoveredOption,
                      disabled: option.disabled,
                    },
                    attrs: {
                      role: "treeitem",
                      "aria-selected": option.selected,
                    },
                    on: {
                      mouseenter: function ($event) {
                        return _vm.onOptionHover(option)
                      },
                      click: function ($event) {
                        return _vm.selectOption(option)
                      },
                    },
                  },
                  [
                    option.src
                      ? _c("img", {
                          staticClass: "c-thumb c-thumb--xxs u-mr",
                          attrs: { src: option.src },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "u-va-middle" }, [
                      _vm._v(
                        "\n          " + _vm._s(option.label) + "\n          "
                      ),
                      option.sublabel
                        ? _c("span", { staticClass: "u-ts-5 u-pl-tiny" }, [
                            _vm._v(_vm._s(option.sublabel)),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.instanceGroups, function (group) {
                return _c(
                  "ul",
                  {
                    key: group.text,
                    staticClass: "px-select-optgroup",
                    attrs: { role: "group" },
                  },
                  [
                    _c("li", { staticClass: "px-select-optgroup--label" }, [
                      _vm._v(
                        "\n          " + _vm._s(group.text) + "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(group.children, function (option) {
                      return _c(
                        "li",
                        {
                          key: option.value,
                          staticClass: "px-select-option",
                          class: {
                            selected: option.selected,
                            active: option === _vm.hoveredOption,
                            disabled: option.disabled,
                          },
                          attrs: {
                            role: "treeitem",
                            "aria-selected": option.selected,
                          },
                          on: {
                            mouseenter: function ($event) {
                              return _vm.onOptionHover(option)
                            },
                            click: function ($event) {
                              return _vm.selectOption(option)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(option.label) + "\n        "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        staticClass: "pixie-select-hfield u-hidden",
        attrs: {
          type: "text",
          name: _vm.name,
          required: _vm.required,
          "data-value-missing": _vm.dataValueMissing,
        },
        domProps: { value: _vm.selectedValue },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }